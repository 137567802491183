import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Header from "./components/header";
import Homepage from "./views/homepage";
import Footer from "./components/footer";
import React from "react";
import WorkPage from "./components/workPage";
import Container from "./components/container";
import {ScreenSizeProvider} from "./contexts/ScreenSizeContext";

export default function App() {
  return (
    <BrowserRouter>
      <ScreenSizeProvider>
        <Container>
          <Header />
          <div id="content">
            <Routes>
              <Route path="/product-development" element={<WorkPage productDevelopment />} />
              <Route path="/cloud-infrastructure" element={<WorkPage cloudInfrastructure />} />
              <Route path="/e-commerce" element={<WorkPage eCommerce />} />
              <Route path="/marketing-branding" element={<WorkPage marketingBranding />} />
              <Route path="/" element={<Homepage />} />
              {/*Redirects all other URLs to the homepage*/}
              <Route path="*" element={<Homepage />} />
            </Routes>
          </div>
          <Footer />
        </Container>
      </ScreenSizeProvider>
    </BrowserRouter>
  )
}