import { useState, useEffect } from 'react'

export default function useIntersection(element, rootMargin) {
  const [isVisible, setState] = useState(false);
  let current = null;
  useEffect(() => {
    if (!element.current) return;
    if (!current) {
      // eslint-disable-next-line
      current = element.current;
    }
    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(entry.isIntersecting);
      }, { rootMargin }
    );

    current && observer.observe(current);

    return () => observer.unobserve(current);
  }, [element]);

  return isVisible;
};
