import React, { useEffect, useRef } from 'react';
import {scrollText} from '../data/homepage.json'
import FadeIn from "./fadeIn";

let scrollListener = null;
let ticking = false;

export default function ScrollingLogo() {
  const block = useRef(null);
  const text1 = useRef(null);
  const text2 = useRef(null);

  useEffect(() => {
    scrollListener = (e) => {

      if (!ticking) {
        window.requestAnimationFrame(function() {
          const { top, bottom } = block.current.getBoundingClientRect();

          if (top < window.innerHeight && bottom > 0) {
            // visible in viewport
            const ratio = Math.round((top / window.innerHeight) * 100);
            text1.current.style.transform = `translateX(${ratio}%)`;
            text2.current.style.transform = `translateX(${ratio}%)`;
          }

          ticking = false;
        });

        ticking = true;
      }
    };

    document.addEventListener('scroll', scrollListener);

    return () => document.removeEventListener('scroll', scrollListener);
  }, []);

  return (
    <FadeIn>
      <section id="scrollingLogo">
        <div className="green-block" ref={block}>
          <p className="scroll-text" ref={text1}>{scrollText}</p>
          <div className="circle">
            <p className="scroll-text" ref={text2}>{scrollText}</p>
          </div>
        </div>
      </section>
    </FadeIn>
  );
}
