import React from 'react';
import FadeIn from "./fadeIn";

export default function WorkClient({client, hideWorkClient}) {
  const {title, subtitle, copy, tasks, images, imageTemplate, linksSection} = client;
  const halfTasks =  Math.ceil(tasks.length / 2);

  const leftTasks =  tasks.slice(0, halfTasks);
  const rightTasks =  tasks.slice(halfTasks);

  return (
    <FadeIn>
      <section id="client" style={{visibility: hideWorkClient ? 'hidden' : 'visible'}}>
        <FadeIn appearOnChange>
          <p className={'work-title'}>
            {title}
          </p>
          {subtitle && <p className={'work-subtitle'}>
            {subtitle}
          </p>}
        </FadeIn>
        {copy && <FadeIn appearOnChange>
          <div className={'work-copy'}>
            <div dangerouslySetInnerHTML={{__html: copy}}/>
          </div>
        </FadeIn>}
        {tasks.length > 0 && <FadeIn appearOnChange>
          <div className={'task-wrapper'}>
            <div className={'task-group'}>
              {leftTasks.map((task, idx) => <p className={'task'} key={`${task}-${idx}`}>{task}</p>)}
            </div>
            <div className={'task-group'}>
              {rightTasks.map((task, idx) => <p className={'task'} key={`${task}-${idx}`}>{task}</p>)}
            </div>
          </div>
        </FadeIn>}
        {images.length > 0 && <div className={`client-image-wrapper ${imageTemplate}`}>
            {images.map((img, idx) => <FadeIn key={`${title} ${idx + 1}`}><img src={img} alt={`${title} ${idx + 1}`}/></FadeIn>)}
          </div>}
        {linksSection && linksSection.length && <div className={'links-section-wrapper'}>
          {linksSection.map(section => <div key={`${section.title}`} className={'link-section-wrapper'}>
            <FadeIn>
              <img alt={`${section.title} logo`} src={section.image} />
            </FadeIn>
            <div className={'articles'}>
              {section.articles.map((a, idx) => {
                return (
                  <FadeIn key={`${a.title}_${idx}`}>
                    <div className={'article'}>
                      <div className={'article-title'}>
                        {a.title}
                      </div>
                      <div className={'article-button'}>
                        <button onClick={() => window.open(a.link)}>View</button>
                      </div>
                    </div>
                  </FadeIn>
                )
              })}
            </div>
          </div>)}
        </div>}
      </section>
    </FadeIn>
  );
}
