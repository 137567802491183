import React, {useEffect, useState} from 'react';
import {hero} from '../data/homepage.json'
import Frame from "./frame";
import FadeIn from "./fadeIn";

export default function Hero() {
	const [rotatingWord, setRotatingWord] = useState(hero.rollingWords[0]);
	const lastIdx = hero.rollingWords.length - 1;

	useEffect(() => {
		let nextIdx = 1;

		const interval = setInterval(() => {
			setRotatingWord(hero.rollingWords[nextIdx]);
			nextIdx = nextIdx >= lastIdx ? 0 : nextIdx + 1;
		}, 2500);
		return () => clearInterval(interval);
	}, [lastIdx]);

	return (
		<FadeIn>
			<section id="hero">
				<Frame>
					<div className="copy-wrapper">
						{hero.copy}
					</div>
					<div>
						<div className={"copy-wrapper rolling-word"}>
							{rotatingWord}
						</div>
					</div>
				</Frame>
			</section>
		</FadeIn>
	);
}
