import React, {createRef, useEffect, useState} from 'react';
import Nav from './nav';
import {useNavigate, useLocation} from "react-router-dom";
import useIntersection from "./useIntersection";

export default function Header(props) {
	const ref = createRef();
	const [lastScroll, setLastScroll] = useState(0);
	const [scrollUp, setScrollUp] = useState(false);
	const navigate = useNavigate();
	const {pathname} = useLocation();
	const homepage = pathname === '/';
	const className = !homepage ? 'inverse' : '';
	const screenIntersection = useIntersection(ref, '-70px');

	useEffect(() => {
			window.addEventListener('scroll', onScroll);
			document.body.addEventListener('touchmove', onScroll);
		return () => {
			window.removeEventListener('scroll', onScroll);
			document.body.removeEventListener('touchmove', onScroll);
		};
	}, [onScroll])

	// eslint-disable-next-line react-hooks/exhaustive-deps
	function onScroll() {
		const st = window.scrollY || document.documentElement.scrollTop;
		let scrollDiff = st - lastScroll;
		setLastScroll(st <= 0 ? 0 : st);
		setScrollUp(scrollDiff < 0);
	}

	return (
		<header ref={ref} className={`${className}`} id={'header'}>
			<div className={`${className} main-header`}>
				<div className={'logo-wrapper'}>
					<h1
						className={className}
						onClick={() => navigate('/')}
					>
						BlueInGreen - A problem-solving collective
					</h1>
				</div>

				<Nav inverted={!homepage} />
			</div>
			<div className={`${className} fixed-header ${scrollUp && !screenIntersection ? 'show' : ''}`}>
				<div className={'logo-wrapper'}>
					<h1
						className={className}
						onClick={() => navigate('/')}
					>
						BlueInGreen - A problem-solving collective
					</h1>
				</div>

				<Nav inverted={!homepage}/>
			</div>
		</header>
	);
}
