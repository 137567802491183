import React, {useEffect, useState} from 'react';
import {cloudInfrastructure as cloudData} from '../data/cloudInfrastucture.json'
import {eCommerce as commerceData} from '../data/eCommerce.json'
import {marketingBranding as marketingData} from '../data/marketingBranding.json'
import {productDevelopment as prodData} from '../data/productDevelopment.json'
import WorkClient from "./workClient";
import Frame from "./frame";
import WorkList from "./workList";
import FadeIn from "./fadeIn";

export default function WorkPage({
  cloudInfrastructure,
  productDevelopment,
  eCommerce,
  marketingBranding,
}) {
  const data = cloudInfrastructure ? cloudData :
    productDevelopment ? prodData
  : eCommerce ? commerceData
  : productDevelopment ? prodData
  : marketingBranding ? marketingData
  : cloudData;

  const {title, clients} = data;
  const [currentClient, setCurrentClient] = useState(clients[0]);
  const [hideWorkClient, setHideWorkClient] = useState(false);

  useEffect(() => {
    setCurrentClient(clients[0]);
  }, [data, clients]);

  const onClientClick = (client) => {
    if(client === currentClient) return;
    setCurrentClient(client);
    setHideWorkClient(true);
    setTimeout(() => {setHideWorkClient(false)}, 10);
  };

  return (
   <div id="workPage">
    <Frame>
      <FadeIn>
        <p className={'title'}>{title}</p>
      </FadeIn>
    </Frame>
    <FadeIn>
      <div className={'client-btn-group'}>
        {clients.map((client) => {
          return <button key={client?.cta} className={`${(currentClient === client) ? 'active-client' : ''}`} onClick={() => onClientClick(client)}>{client?.cta}</button>
        })}
      </div>
    </FadeIn>
   <Frame>
     <WorkClient hideWorkClient={hideWorkClient} client={currentClient} />
    </Frame>
    <WorkList inverse />
   </div>
 );
}
