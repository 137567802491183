import React, { useEffect, useRef } from 'react';
import {press, articles} from '../data/homepage.json'
import FadeIn from "../components/fadeIn";

let scrollListener = null;
let ticking = false;

export default function Press() {
  const {title, links} = press;
  const box = useRef(null);
  const topArticles = useRef(null);
  const masked = useRef(null);

  useEffect(() => {
    scrollListener = (e) => {

      if (!ticking) {
        window.requestAnimationFrame(function() {
          const offset = box.current.getBoundingClientRect().height / 2;
          const { top, bottom, height } = topArticles.current.getBoundingClientRect();
          const threshold = window.innerWidth > 600 ? 80 : -18;

          if (top < (window.innerHeight / 2) && bottom > (height/2) + offset) {
            // visible in viewport
            let marginTop = top - (window.innerHeight / 2) + offset;
            // console.log(top, marginTop);
            if (marginTop < -height) {
              marginTop = -height;
            }

            if (marginTop > threshold) {
              marginTop = threshold;
            }

            masked.current.style.marginTop = `${marginTop}px`;
          }

          ticking = false;
        });

        ticking = true;
      }
    };

    document.addEventListener('scroll', scrollListener);

    return () => document.removeEventListener('scroll', scrollListener);
  }, []);

  return (
    <FadeIn>
      <section id="press">
        <p className="title">{title}</p>

        <div id="blackBox" ref={box}>
          <div className="masked-articles" ref={masked}>
            {links.map((l, idx) => {
              return (<div className="article white" key={idx}>{l.title}</div>)
            })}
          </div>
        </div>

        <div className="top-articles" ref={topArticles}>
          {links.map((l, idx) => {
            return (<div className="article" key={idx}>{l.title}</div>)
          })}
        </div>
      </section>

      <section id="press-news">
        <div className={'bottom-articles'}>
          {articles.map((a, idx) => {
            return (
              <FadeIn key={`${a.title}_${idx}`}>
                <div className={'article'}>
                  <div className={'article-title'}>
                    { a.image && <img src={a.image} className="article-image" alt={a.title} /> }
                    {a.title}
                  </div>
                  <div className={'article-button'}>
                    <button onClick={() => window.open(a.link)}>View</button>
                  </div>
                </div>
              </FadeIn>
            )
          })}
        </div>
      </section>
    </FadeIn>
  );
}
