import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function Container(props) {
  const {pathname} = useLocation();
  const homepage = pathname === '/';
  const backgroundColor = homepage ? "#E5E5E5" : "#181818";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <div style={{
    backgroundColor,
    margin: 0,
    padding: 0,
    minHeight: window.innerHeight,
    maxWidth: '100vw',
    // overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    transition: 'background-color 1s linear'
  }}>{props.children}</div>
};