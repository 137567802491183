import React, {useContext, useState} from 'react';
import {useNavigate, useLocation} from "react-router-dom";
import SidePanel from "./sidePanel";
import {ScreenSizeContext} from "../contexts/ScreenSizeContext";

export default function Nav(props) {
	const {mobile} = useContext(ScreenSizeContext);
	const [showMenu, setShowMenu] = useState(false);
	const {pathname} = useLocation();
	const navigate = useNavigate();
	const handleNavClick = (e) => {
		e.preventDefault();
		const section = e.target.id.replace('-nav', '');
		if (showMenu) {
			setShowMenu(false);
		}
		if (pathname !== '/') {
			navigate('/', {state: {targetClass: section}});
			setTimeout(() => {
				let element = document.getElementById(section);
				element.scrollIntoView({ behavior: 'smooth' });
			}, 200);
		} else {
			navigate('/', {state: {targetClass: section}});
			let element = document.getElementById(section);
			element.scrollIntoView({ behavior: 'smooth' });
		}
	}

	return (
		<React.Fragment>
			<nav>
				{!props.footer && mobile && <div
					onClick={() => setShowMenu(!showMenu)}
					className={`nav-button ${props.inverted ? 'inverse' : ''}`}
				/>}
				{(props.footer || !mobile) && (
					<React.Fragment>
						<a href="/work" id={'work-nav'} className={`work ${props.inverted ? 'inverse' : ''}`} onClick={handleNavClick}>Work</a>
						<a href="/team" id={'about-nav'} className={`about ${props.inverted ? 'inverse' : ''}`} onClick={handleNavClick}>About</a>
						<a href="/team" id={'capabilities-nav'} className={`capabilities ${props.inverted ? 'inverse' : ''}`} onClick={handleNavClick}>Capabilities</a>
						<a href="/press" id={'press-nav'} className={`press ${props.inverted ? 'inverse' : ''}`} onClick={handleNavClick}>Press</a>
						{/* TODO: Bring back when we have open positions */}
						{/*<a href="/careers" id={'careers'} className={`careers ${props.inverted ? 'inverse' : ''}`} onClick={handleNavClick}>Careers</a>*/}
						<a href="mailto:info@bluein.green" id={'connect-nav'} className={`connect ${props.inverted ? 'inverse' : ''}`}>Connect</a>
					</React.Fragment>
				)}
			</nav>
			{!props.footer && <SidePanel
				handleNavClick={handleNavClick}
				setShowMenu={setShowMenu}
				showMenu={showMenu}
			/>}
		</React.Fragment>
	);
}
