import React from 'react';
import {work} from '../data/homepage.json'
import {useNavigate, useLocation} from "react-router-dom";
import FadeIn from "./fadeIn";

export default function WorkList(props) {
  const {types} = work;
  const {pathname} = useLocation();
  const homepage = pathname === '/';
  const navigate = useNavigate();
  return (
    <FadeIn>
      <div id={'workTypes'}>
      {types.map(type => {
        const title = type.title
          .toLowerCase()
          .replaceAll(' / ', ' ')
          .replaceAll(' ', '-');
        const className = props.inverse ? `${title} inverse` : title;
        return (
          <div key={type.title}>
            <div className={'work-type-wrapper'} key={type.title}>
                <div className={'work-image-wrapper'}>
                  <div className={`work-image ${className}`} onClick={() => navigate(`/${title}`)} />
                </div>
                <p className={`work-title ${!homepage ? 'inverse' : ''}`}>{type.title}</p>
                <p className={`work-copy ${!homepage ? 'inverse' : ''}`}>{type.copy}</p>
                <button className={`${props.inverse ? 'inverse' : ''}`} onClick={() => navigate(`/${title}`)}>
                  View
                </button>

            </div>
          </div>
        )
      })}
      </div>
    </FadeIn>
  );
}
