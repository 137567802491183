import {createRef, useContext, useEffect, useState} from 'react'
import useIntersection from "./useIntersection";
import {ScreenSizeContext} from "../contexts/ScreenSizeContext";

export default function FadeIn(props) {
  const { desktop } = useContext(ScreenSizeContext);
  const rootMargin = desktop ? '20px' : '0px';
  const ref = createRef();
  const [fadeIn, setFadeIn] = useState(false);
  const [innerText, setInnerText] = useState(ref?.current?.innerText);
  const inViewport = useIntersection(ref, rootMargin);
  const propsClass = props?.className ?? '';
  useEffect(() => {
    // Some components need to show a fade in effect when the div contents change
    if (props?.appearOnChange && ref?.current?.innerText && ref?.current?.innerText !== innerText) {
      setFadeIn(false);
      setInnerText(ref?.current?.innerText);
    }

    if (!fadeIn && inViewport) {
      setFadeIn(true);
    }
    // eslint-disable-next-line
  }, [inViewport, ref]);

  return (<div ref={ref} className={`${propsClass} ${fadeIn ? 'fade-in' : 'hidden'}`}>{props.children}</div>)
};