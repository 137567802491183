import React from 'react';
import Frame from './frame';
import Nav from './nav';
import {useLocation} from "react-router-dom";

const year = new Date().getFullYear();

export default function Footer() {
	const {pathname} = useLocation();
	const homepage = pathname === '/';
	const className = !homepage ? 'inverse' : '';
	return (
		<footer className={className}>
			<Frame>
				<div className={'footer-wrapper'}>
					<Nav inverted={!homepage} footer={true} />
				</div>
				<div className="copyright-phone-wrapper">
					<div className="copyright">Copyright © {year} BlueinGreen, Inc</div>
					<div className="phone"><a href="tel:1-866-258-3464">866-258-3464 (866-BLUEING)</a></div>
				</div>
			</Frame>
		</footer>
	);
}
