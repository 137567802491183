import React from 'react';
import Frame from '../components/frame';
import {capabilities} from '../data/homepage.json';
import plusIcon from '../assets/images/plus-icon.svg'
import FadeIn from "../components/fadeIn";

export default function Capabilities(props) {
  const {title, types} = capabilities;
  const topTypes = [types[0], types[1]];
  const bottomTypes = [types[2], types[3]];
  return (
    <FadeIn>
      <section id="capabilities">
        <Frame>
          <p className={'title'}>{title}</p>
          <FadeIn>
            <div className={'types-wrapper'}>
              {topTypes.map(t => {
                return (
                  <div key={t.title}>
                    <p className={'type-title'}>{t.title}</p>
                    {t.abilities.map(ability => <p className={'abilities'} key={ability}>{ability}</p>)}
                  </div>
                )
              })}
            </div>
          </FadeIn>
          <FadeIn>
            <div className={'plus-icon-wrapper'}>
              <img src={plusIcon} alt={'plus icon'} />
            </div>
          </FadeIn>
          <FadeIn>
            <div className={'types-wrapper'}>
              {bottomTypes.map(t => {
                return (
                  <div key={t.title}>
                    <p className={'type-title'}>{t.title}</p>
                    {t.abilities.map((ability, idx) => <p key={`${ability}_${idx}`} className={'abilities'}>{ability}</p>)}
                  </div>
                )
              })}
            </div>
          </FadeIn>
        </Frame>
      </section>
    </FadeIn>
  );
}
