import React from 'react';
import Frame from '../components/frame';
import {bios} from '../data/bios.json'
import FadeIn from "../components/fadeIn";

export default function Bios() {

  const shuffleArray = (array) => {
  return array.map(value => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
  }

    const getBioDivs = () => {
      const shuffledBios = shuffleArray(bios);
      let divs = [];
      for (let i = 0; i < 3; i++) {
        const img = shuffleArray(shuffledBios[i].img)[0]
        divs.push(<FadeIn key={`bio_${i}`}>
          <div className={`bio${i+1}-wrapper`}>
            <div className={'bio-image'}>
              <FadeIn>
                <img src={img} alt={shuffledBios[i].name} />
              </FadeIn>
            </div>
            <FadeIn>
              <div className={'bio-details'}>
                <p className={'bio-title'}>{shuffledBios[i].name} / {shuffledBios[i].position}</p>
                <p className={'bio-copy'} dangerouslySetInnerHTML={{__html: shuffledBios[i].bio}} />
              </div>
            </FadeIn>
          </div>
        </FadeIn>)
      }
    return divs;
  }

  return (
    <FadeIn>
      <section id="bios">
        <Frame>
          <div className={'wrapper'}>
            {getBioDivs()}
          </div>
        </Frame>
      </section>
    </FadeIn>
  );
}
