import React from 'react';
import {clients} from '../data/homepage.json';
import yl from '../assets/images/client-logos/Young-Living.svg';
import nike from '../assets/images/client-logos/Nike.svg';
import coke from '../assets/images/client-logos/Coca-Cola.svg';
import delta from '../assets/images/client-logos/Delta.svg';
import FadeIn from "../components/fadeIn";

export default function Clients(props) {
  const {title} = clients;
  return (
    <FadeIn>
      <section id="clients">
        <FadeIn>
          <p className={'clients-title'}>{title}</p>
        </FadeIn>
        <FadeIn>
          <div className={'client-logo-wrapper'}>
            <img src={yl} alt={"Young Living"} />
            <img src={nike} alt={"Nike"} />
            <img src={coke} alt={"Coca-Cola"} />
            <img src={delta} alt={"Delta"} />
          </div>
        </FadeIn>
      </section>
    </FadeIn>
  );
}
