import React from 'react';
import Hero from "../components/hero";
import Work from "./work";
import Clients from "./clients";
import ScrollingLogo from "../components/scrollingLogo";
import About from "./about";
import Bios from "./bios";
import Capabilities from "./capabilities";
import Press from "./press";
// import Careers from "./careers";
import {useLocation} from "react-router-dom";

export default function Homepage(props) {
  const {pathname} = useLocation();

  // replaces unknown URL from redirection with /
  if (pathname !== '/') {
    window.location.href = '/';
  }
  return (
    <div id="homepage">
      <Hero />
      <Work />
      <Clients />
      <ScrollingLogo />
      <About />
      <Bios />
      <Capabilities />
      <Press />
      {/* TODO: Bring back when we have positions open */}
      {/*<Careers />*/}
    </div>
  );
}
