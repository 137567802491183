import React from 'react';
import Frame from '../components/frame';
import {about} from '../data/homepage.json'
import FadeIn from "../components/fadeIn";

export default function About() {
  const {title, copy} = about;
  return (
    <FadeIn>
      <section id="about">
        <Frame>
          <FadeIn>
            <p className={'title'}>{title}</p>
          </FadeIn>
          <FadeIn>
            <p className={'copy'}>{copy}</p>
          </FadeIn>
        </Frame>
      </section>
    </FadeIn>
  );
}
