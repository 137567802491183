import React, { createContext, useEffect, useState } from 'react';
import {debounce} from 'lodash';

export const ScreenSizeContext = createContext({});

export const ScreenSizeProvider = ({ children }) => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setInnerWidth(window.innerWidth);
    }
    // Add event listener
    window.addEventListener('resize', debounce(handleResize, 200));
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <ScreenSizeContext.Provider
      value={{
        desktop: innerWidth >= 960,
        tablet: innerWidth < 960 && innerWidth > 600,
        mobile: innerWidth <= 600,
        width: innerWidth
      }}>
      {children}
    </ScreenSizeContext.Provider>
  );
};
