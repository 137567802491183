import React from 'react';
import {work} from '../data/homepage.json'
import WorkList from '../components/workList';
import FadeIn from '../components/fadeIn';

export default function Work() {
	const {title, copy} = work;

	return (
		<FadeIn>
			<section id="work">
				<FadeIn>
					<p className={'title'}>{title}</p>
				</FadeIn>
				<FadeIn>
					<p className={'copy'}>{copy}</p>
				</FadeIn>
				<WorkList />
			</section>
		</FadeIn>
	);
}
