import React, {useEffect} from 'react';
import closeButton from "../assets/images/close-icon.svg";


export default function SidePanel({
  showMenu,
  setShowMenu,
  handleNavClick
}) {

  const closePanel = ({key}) => {
    if (key.toLowerCase() === 'escape' || key.toLowerCase() === 'esc') {
      setShowMenu(false);
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', closePanel);
    return () => {document.removeEventListener('keydown', closePanel)}
  })

  return (
    <div id="side-panel" className={`${showMenu ? 'open' : ''}`}>
      <div className={`close-row`}>
        <img alt={'close'} onClick={() => setShowMenu(false)} src={closeButton}/>
      </div>
      <div className="panel-links">
        <a href="/work" id={'work-nav'} className="work" onClick={handleNavClick}>Work</a>
        <a href="/team" id={'about-nav'} className="about" onClick={handleNavClick}>About</a>
        <a href="/team" id={'capabilities-nav'} className="capabilities" onClick={handleNavClick}>Capabilities</a>
        <a href="/press" id={'press-nav'} className="press" onClick={handleNavClick}>Press</a>
        {/*<a href="/careers" id={'work-nav'} className="careers" onClick={handleNavClick}>Careers</a>*/}
        <a href="mailto:info@bluein.green" id={'connect-nav'} className="connect">Connect</a>
      </div>
    </div>
  );
}
